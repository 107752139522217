<template>
  <div class="cloud-control">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="安全信息" name="first">
        <div class="cloud-list-tools">
          <el-form class="battery-form" :inline="true" :model="search">
            <el-form-item label="设备编号:">
              <el-input
                class="mr20"
                v-model="search.sn"
                style="width: 220px"
                placeholder="输入设备编号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="信息等级:">
              <el-select v-model="search.faultLevel" style="width: 220px">
                <el-option label="全部" value=""></el-option>
                <el-option label="5级" :value="5"></el-option>
                <el-option label="4级" :value="4"></el-option>
                <el-option label="3级" :value="3"></el-option>
                <el-option label="2级" :value="2"></el-option>
                <el-option label="1级" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="信息状态:">
              <el-select v-model="search.faultStatus" style="width: 220px">
                <el-option label="全部" value=""></el-option>
                <el-option label="未处理" :value="0"></el-option>
                <el-option label="已处理" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发生时间:">
              <el-date-picker
                v-model="search.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange"
              >
              </el-date-picker>
            </el-form-item>
            <div style="float: right">
              <el-form-item>
                <el-button type="primary" size="small" @click="getData"
                  >查询</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button @click="resetForm()">重置</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="cloud-table-content">
          <el-table
            highlight-current-row
            stripe
            height="500px"
            :data="list"
            key="MenuList"
          >
          <el-table-column v-if="search.organId === '99'" label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.pfsn}}</a>
            </template>
          </el-table-column>
          <el-table-column v-else label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.sn}}</a>
            </template>
          </el-table-column>
            <el-table-column
              prop="faultStatus"
              :formatter="faultStatusStr"
              label="信息状态"
            >
            </el-table-column>
            <el-table-column prop="info" label="故障名称"> </el-table-column>
            <el-table-column prop="info" label="当前处理人">
              <template slot-scope="scope">
                {{scope.row.senderText || '-'}}
              </template>
            </el-table-column>
            
            <el-table-column
              prop="faultCode"
              label="故障代码"
            >
            </el-table-column>
            <el-table-column
              prop="receiver"
              label="当前状态"
            >
              <template slot-scope="scope">
                {{scope.row.ompStatus | ompStatusStr}}
              </template>
            </el-table-column>
            <el-table-column
              prop="faultLevel"
              :formatter="faultLevelStr"
              label="信息等级"
            >
            </el-table-column>
            
            <el-table-column
              prop="receiver"
              label="下一级处理人"
            >
              <template slot-scope="scope">
                {{scope.row.receiverText || '-'}}
              </template>
            </el-table-column>
            <el-table-column
              prop="receiver"
              label="问题类型"
            >
              <template slot-scope="scope">
                {{scope.row.tagTypeText || '-'}}
              </template>
            </el-table-column>
            <el-table-column prop="faultTime" label="发生时间" sortable  width="200">
            </el-table-column>
            <el-table-column prop="interval" label="持续时间">
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button @click="infoHandle(scope.row)" type="text"
                  >查看</el-button
                >
                <el-button
                  v-if="scope.row.faultStatus === 0"
                  @click="setHandle(scope.row)"
                  type="text"
                  >处理</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="cloud-page-container">
          <div class="cloud-table-page-container">
            <el-pagination
              background
              :current-page="search.pageIndex"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="离线信息" name="second">
        <div class="cloud-list-tools">
          <el-form class="battery-form" :inline="true" :model="search">
            <el-form-item label="设备编号:">
              <el-input
                class="mr20"
                v-model="search.sn"
                style="width: 220px"
                placeholder="输入设备编号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="信息等级:">
              <el-select v-model="search.faultLevel" style="width: 220px">
                <el-option label="全部" value=""></el-option>
                <el-option label="5级" :value="5"></el-option>
                <el-option label="4级" :value="4"></el-option>
                <el-option label="3级" :value="3"></el-option>
                <el-option label="2级" :value="2"></el-option>
                <el-option label="1级" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="信息状态:">
              <el-select v-model="search.faultStatus" style="width: 220px">
                <el-option label="全部" value=""></el-option>
                <el-option label="未处理" :value="0"></el-option>
                <el-option label="已处理" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发生时间:">
              <el-date-picker
                v-model="search.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange"
              >
              </el-date-picker>
            </el-form-item>
            <div style="float: right">
              <el-form-item>
                <el-button type="primary" size="small" @click="getData"
                  >查询</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button @click="resetForm()">重置</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="cloud-table-content">
          <el-table
            highlight-current-row
            stripe
            height="500px"
            :data="list"
            key="MenuList"
          >
            <el-table-column v-if="search.organId === '99'" label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.pfsn}}</a>
            </template>
          </el-table-column>
          <el-table-column v-else label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.sn}}</a>
            </template>
          </el-table-column>
            <el-table-column
              prop="faultStatus"
              :formatter="faultStatusStr"
              label="信息状态"
            >
            </el-table-column>
            <el-table-column prop="info" label="故障名称"> </el-table-column>
            <el-table-column prop="info" label="当前处理人">
              <template slot-scope="scope">
                {{scope.row.senderText || '-'}}
              </template>
            </el-table-column>
            
            <el-table-column
              prop="faultCode"
              label="故障代码"
            >
            </el-table-column>
            <el-table-column
              prop="receiver"
              label="当前状态"
            >
              <template slot-scope="scope">
                {{scope.row.ompStatus | ompStatusStr}}
              </template>
            </el-table-column>
            <el-table-column
              prop="faultLevel"
              :formatter="faultLevelStr"
              label="信息等级"
            >
            </el-table-column>
            <el-table-column
              prop="receiver"
              label="下一级处理人"
            >
              <template slot-scope="scope">
                {{scope.row.receiverText || '-'}}
              </template>
            </el-table-column>
            <el-table-column
              prop="receiver"
              label="问题类型"
            >
              <template slot-scope="scope">
                {{scope.row.tagTypeText || '-'}}
              </template>
            </el-table-column>
            <el-table-column prop="faultTime" label="发生时间" sortable width="200">
            </el-table-column>
            <el-table-column prop="interval" label="持续时间">
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button @click="infoHandle(scope.row)" type="text"
                  >查看</el-button
                >
                <el-button
                  v-if="scope.row.faultStatus === 0"
                  @click="setUpOffHandle(scope.row)"
                  type="text"
                  >处理</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="cloud-page-container">
          <div class="cloud-table-page-container">
            <el-pagination
              background
              :current-page="search.pageIndex"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="低电量信息" name="third">
        <div class="cloud-list-tools">
          <el-form class="battery-form" :inline="true" :model="search">
            <el-form-item label="设备编号:">
              <el-input
                class="mr20"
                v-model="search.sn"
                style="width: 220px"
                placeholder="输入设备编号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="信息等级:">
              <el-select v-model="search.faultLevel" style="width: 220px">
                <el-option label="全部" value=""></el-option>
                <el-option label="5级" :value="5"></el-option>
                <el-option label="4级" :value="4"></el-option>
                <el-option label="3级" :value="3"></el-option>
                <el-option label="2级" :value="2"></el-option>
                <el-option label="1级" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="信息状态:">
              <el-select v-model="search.faultStatus" style="width: 220px">
                <el-option label="全部" value=""></el-option>
                <el-option label="未处理" :value="0"></el-option>
                <el-option label="已处理" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发生时间:">
              <el-date-picker
                v-model="search.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange"
              >
              </el-date-picker>
            </el-form-item>
            <div style="float: right">
              <el-form-item>
                <el-button type="primary" size="small" @click="getData"
                  >查询</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button @click="resetForm()">重置</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="cloud-table-content">
          <el-table
            highlight-current-row
            stripe
            height="500px"
            v-loading="loading"
            :data="list"
            key="MenuList"
          >
            <el-table-column v-if="search.organId === '99'" label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.pfsn}}</a>
            </template>
          </el-table-column>
          <el-table-column v-else label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              <a @click="gotoDetail(scope.row)" style="color: blue">{{scope.row.sn}}</a>
            </template>
          </el-table-column>
            <el-table-column
              prop="faultStatus"
              :formatter="faultStatusStr"
              label="信息状态"
            >
            </el-table-column>
            <el-table-column prop="info" label="故障名称"> </el-table-column>
            <el-table-column prop="info" label="当前处理人">
              <template slot-scope="scope">
                {{scope.row.senderText || '-'}}
              </template>
            </el-table-column>
            
            <el-table-column
              prop="faultCode"
              label="故障代码"
            >
            </el-table-column>
            <el-table-column
              prop="receiver"
              label="当前状态"
            >
              <template slot-scope="scope">
                {{scope.row.ompStatus | ompStatusStr}}
              </template>
            </el-table-column>
            <el-table-column
              prop="faultLevel"
              :formatter="faultLevelStr"
              label="信息等级"
            >
            </el-table-column>
            <el-table-column
              prop="receiver"
              label="下一级处理人"
            >
              <template slot-scope="scope">
                {{scope.row.receiverText || '-'}}
              </template>
            </el-table-column>
            <el-table-column
              prop="receiver"
              label="问题类型"
            >
              <template slot-scope="scope">
                {{scope.row.tagTypeText || '-'}}
              </template>
            </el-table-column>
            <el-table-column prop="faultTime" label="发生时间" sortable width="200">
            </el-table-column>
            <el-table-column prop="interval" label="持续时间">
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button @click="infoHandle(scope.row)" type="text"
                  >查看</el-button
                >
                <el-button
                  v-if="scope.row.faultStatus === 0"
                  @click="setUpHandle(scope.row)"
                  type="text"
                  >处理</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="cloud-page-container">
          <div class="cloud-table-page-container">
            <el-pagination
              background
              :current-page="search.pageIndex"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="search.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <Handle v-if="visable" ref="handleModel" @refreshDataList="getData" />
    <LowHandle v-if="lowVisable" ref="lowModel" @refreshDataList="getData" />
    <OffHandle v-if="infoVisable" ref="infoModel"/>
  </div>
</template>

<script>
import "./Index.less";
import axios from "@/utils/axios";
import Handle from "./components/handle";
import LowHandle from './components/LowHandle'
import OffHandle from './components/OffHandle'
export default {
  name: "CloudControlIndex",
  components: {
    Handle,
    LowHandle,
    OffHandle
  },
  data() {
    return {
      activeName: "first",
      visable: false,
      loading: false,
      lowVisable: false,
      infoVisable: false,
      total: 0,
      search: {
        sn: null,
        faultLevel: "",
        faultStatus: "",
        time: null,
        startTime: null,
        endTime: null,
        type: 1,
        pageIndex: 1,
        pageSize: 20,
        organId: null,
        orderBy: null
      },
      list: [],
    };
  },
  mounted() {
    this.search.organId = localStorage.getItem("organId");
    this.search.sn = this.$route.params.sn;
    this.search.type = this.$route.params.type?this.$route.params.type:1;
    this.activeName = this.$route.params.activeName?this.$route.params.activeName:'first';
    this.getData();
  },
  methods: {
    //切换tab
    handleClick(tab, event) {
      this.activeName = tab.name;
      this.search = {
        sn: null,
        faultLevel: "",
        faultStatus: "",
        time: null,
        startTime: null,
        endTime: null,
        type: this.search.type,
        pageIndex: 1,
        pageSize: 20,
        organId: localStorage.getItem("organId"),
        orderBy: null
      }
      if (tab.name === "first") {
        this.search.type = 1;
      } else if (tab.name === "second") {
        this.search.type = 3;
      } else if (tab.name === "third") {
        this.search.type = 2;
      }
      this.getData();
    },
    //获取列表数据
    getData() {
      this.loading = true;
      let { search } = this;
      this.list = null;
      axios
        .post(`/api/cloudControl/getCloudControlPage`, search)
        .then((rs) => {
          const { list, total } = rs;
          this.list = list;
          this.total = total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.search.startTime = this.DateToStr(data[0]);
        this.search.endTime = this.DateToStr(data[1]);
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    //重置
    resetForm() {
      this.search = {
        sn: null,
        faultLevel: "",
        faultStatus: "",
        time: null,
        startTime: null,
        endTime: null,
        type: this.search.type,
        pageIndex: 1,
        pageSize: 20,
        orderBy: null,
        organId: localStorage.getItem("organId"),
      };
      this.getData();
    },
    //处置
    async setHandle(item) {
      let status = ''
      await axios
        .post(`/api/cloudControl/snStatus`, {id: item.sn})
        .then((res) => {
          if(res === 2) {
            status = "(回调中)"
          } else if(res === 3) {
            status = "(售后中)"
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
      this.visable = true;
      this.$nextTick(() => {
        this.$refs.handleModel.init();
        this.$refs.handleModel.title = item.sn + status + " 处理";
        this.$refs.handleModel.dataForm.sn = item.sn;
        this.$refs.handleModel.dataForm.cloudId = item.id;
      });
    },
    async setUpHandle(item) {
      let status = ''
      await axios
        .post(`/api/cloudControl/snStatus`, {id: item.sn})
        .then((res) => {
          if(res === 2) {
            status = "(回调中)"
          } else if(res === 3) {
            status = "(售后中)"
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
      this.lowVisable = true;
      this.$nextTick(() => {
        this.$refs.lowModel.init();
        this.$refs.lowModel.title = item.sn + status + " 处理";
        this.$refs.lowModel.dataForm.sn = item.sn;
        this.$refs.lowModel.dataForm.cloudId = item.id;
        this.$refs.lowModel.dataForm.index = 3
      });
    },
    async setUpOffHandle(item) {
      let status = ''
      await axios
        .post(`/api/cloudControl/snStatus`, {id: item.sn})
        .then((res) => {
          if(res === 2) {
            status = "(回调中)"
          } else if(res === 3) {
            status = "(售后中)"
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
      this.lowVisable = true;
      this.$nextTick(() => {
        this.$refs.lowModel.init();
        this.$refs.lowModel.title = item.sn + status + " 处理";
        this.$refs.lowModel.dataForm.sn = item.sn;
        this.$refs.lowModel.dataForm.cloudId = item.id;
        this.$refs.lowModel.dataForm.index = 2
      });
    },
    infoHandle(item) {
      this.infoVisable = true;
      this.$nextTick(() => {
        this.$refs.infoModel.init();
        this.$refs.infoModel.title = item.sn + " 查看";
        this.$refs.infoModel.dataForm.sn = item.sn;
        this.$refs.infoModel.dataForm.cloudId = item.id;
        this.$refs.infoModel.dataForm.id = item.sn;
        this.$refs.infoModel.dataForm.time = item.faultTime;
        this.$refs.infoModel.dataForm.index = 2
        this.$refs.infoModel.dataForm.type = this.search.type
      });

    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.search.pageIndex = val;
      this.getData();
    },
    faultStatusStr(item) {
      if (item.faultStatus === 0) {
        return "未处理";
      } else if (item.faultStatus === 1) {
        return "已处理";
      } else if (item.faultStatus === 3) {
        return "已完结";
      } else {
        return "-";
      }
    },
    sortChange(data) {
      this.search.pageIndex = 1
      let order = '' 
      this.search.orderBy = 'fault_time '+ ''
      this.page.param.sort = getSort(data)
      this.getData()
    },
    faultLevelStr(item) {
      if (item.faultLevel === 1) {
        return "1级";
      } else if (item.faultLevel === 2) {
        return "2级";
      } else if (item.faultLevel === 3) {
        return "3级";
      } else if (item.faultLevel === 4) {
        return "4级";
      } else if (item.faultLevel === 5) {
        return "5级";
      } else {
        return "-";
      }
    },
    gotoDetail(row) {
      this.$router.push({
        name: "DeviceDetail",
        params: {
          sn: row.sn,
          imei: row.sn,
          type: 1
        },
      });
    }
  },
  filters: {
    ompStatusStr(value) {
      let res = '-'
      if(value === 0) {
        res = '故障上传'
      } else if(value === 1) {
        res = '运维处理中'
      } else if(value === 2) {
        res = '回调处理中'
      } else if(value === 3) {
        res = '售后处理中'
      } else if(value === 4) {
        res = '完结'
      } else if(value === 99) {
        res = '其他'
      } 
      return res
    }
  },
};
</script>