<template>
  <el-dialog
    :visible.sync="visible"
    :title="title+' ('+status+')'"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="60%"
  >
    <div class="handle-content">
      <div class="device-control-list-content">
        <el-table
          class="mt20 ft14 c333 it1"
          style="width: 100%"
          height="500px"
          highlight-current-row
          stripe
          :data="logData.dtoList"
          key="MenuList"
        >
          <el-table-column prop="senderText" label="当前处理人">
            <template slot-scope="scope">
              {{scope.row.senderText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="处理内容">
            <template slot-scope="scope">
              {{scope.row.sourceText || '-'}}
            </template>
          </el-table-column>
          <el-table-column prop="receiverText" label="下一级处理人">
            <template slot-scope="scope">
              {{scope.row.receiverText || '-'}}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="处理时间" width="200">
            <template slot-scope="scope">
              {{scope.row.createTime || '-'}}
            </template>
          </el-table-column>
          <el-table-column prop="interval" label="时长">
            <template slot-scope="scope">
              {{scope.row.interval || '-'}}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="说明">
            <template slot-scope="scope">
              {{(scope.row.remark || '-') + (scope.row.senderText?'，联系人：'+scope.row.senderText : '')}}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div class="handle-data" v-for="(item, index) in pageData" :key="index">
        <div class="items">
          <div class="handle-title">{{item.stageText||'-'}}</div>
          <div class="handle-time">{{item.stage===0?item.faultTime:item.createTime}}</div>
          <div class="handle-person">{{item.interval || '-'}}</div>
          <div class="handle-text">{{item.stage === 99 ? item.createBy : item.nextPersonText}}</div>
        </div>
        
        <div class="handle-remark">
          <div class='remark-title'>说明</div>
          <div class='remark-text'>{{item.remark || '-'}}</div>
        </div>
      </div> -->
    </div>
    <template slot="footer">
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
import "./OffHandle.less";
export default {
  data() {
    return {
      title: null,
      dataForm: {
        cloudId: null,
        id: null,
        sn: null,
        type: null,
        time: null
      },
      visible: false,
      pageData: [],
      list: [],
      logData: {},
      status: null
    };
  },
  computed: {
    rules() {
      return {
        action: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        suggest: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        nextPerson: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        contacts: [
          { required: true, message: "必填项不能为空", trigger: "blur" },
        ],
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.dataForm.organId = localStorage.getItem("organId");
      this.$nextTick(() => {
        this.getPersonList();
        this.getList()
        this.getLogData()
      });
    },
    getList() {
      axios
        .post("/api/cloudControl/allFault", this.dataForm)
        .then((rs) => {
          this.list = rs;
        });
    },
    //获取故障处理人列表
    getPersonList() {
      axios
        .post("/api/cloudControl/getDealLog", {
          cloudId: this.dataForm.cloudId
        })
        .then((rs) => {
          this.pageData = rs;
        });
    },
    //获取故障处理人列表
    getLogData() {
      axios
        .post("/api/FaultDealLog/logs", {
          id: this.dataForm.cloudId
        })
        .then((rs) => {
          this.logData = rs;
          this.status = rs.currentStatusText
        });
    },
    actionStr(value) {
      if(this.dataForm.type === 1) {
        if(value === 1) {
          return '确认故障'
        } else if(value === 2) {
          return '无故障'
        } else {
          return '无法确认转技术'
        }
      } else {
        if(value === 1) {
          return '已联系网点'
        } else if(value === 2) {
          return '已联系平台'
        } else if(value === 3) {
          return '已联系用户'
        } else if(value === 4) {
          return '确认已恢复正常，完结'
        } else if(value === 5) {
          return '确认故障，发回调工单'
        } else {
          return '无法确认转技术'
        }
      }
    }
  },
  filters: {
    
  }
};
</script>