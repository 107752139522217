<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="25%"
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item v-if="ompStatus === 99" label="故障分析" :label-width="'90px'" prop="action">
          <el-select
            v-model="dataForm.action"
            placeholder="请选择"
            :style="{ width: '315px' }"
          >
            <el-option label="已联系网点" :value="1"></el-option>
            <el-option label="已联系平台" :value="2"></el-option>
            <el-option label="已联系用户" :value="3"></el-option>
            <el-option label="确认已恢复正常，完结" :value="4"></el-option>
            <el-option label="确认故障，发回调工单" :value="5"></el-option>
            <el-option label="无法确认转技术" :value="6"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-else label="故障分析" :label-width="'90px'" prop="action">
          <el-select
            v-model="dataForm.action"
            placeholder="请选择"
            :style="{ width: '315px' }"
          >
            <el-option label="已联系网点" :value="1"></el-option>
            <el-option label="已联系平台" :value="2"></el-option>
            <el-option label="已联系用户" :value="3"></el-option>
            <el-option label="确认已恢复正常，完结" :value="4"></el-option>
            <el-option label="无法确认转技术" :value="6"></el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item
          label="联系人员"
          v-if="dataForm.action === 1 || dataForm.action === 2 || dataForm.action === 3"
          :label-width="'90px'"
          prop="contacts"
        >
          <el-input v-model="dataForm.contacts" :style="{ width: '315px' }" placeholder="请输入联系人姓名"/>
        </el-form-item>
        <el-form-item
          label="人员选择"
          v-if="dataForm.action === 5"
          :label-width="'90px'"
          prop="nextPerson"
        >
           <el-select
            v-model="dataForm.nextPerson"
            placeholder="请选择"
            filterable
            :style="{ width: '315px' }"
          >
            <el-option
              v-for="(item, index) in persons"
              :key="index"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="人员选择"
          v-if="dataForm.action === 6"
          :label-width="'90px'"
          prop="nextPerson"
        >
          <el-select
            v-model="dataForm.nextPerson"
            placeholder="请选择"
            filterable
            :style="{ width: '315px' }"
          >
            <el-option
              v-for="(item, index) in persons"
              :key="index"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="问题类型"
          :label-width="'90px'"
          prop="tagType"
        >
          <el-select
            v-model="dataForm.tagType"
            placeholder="问题类型"
            size="small"
            class="area-form-item"
            :style="{ width: '315px' }"
            @change="changeHandle"
          >
            <el-option
              v-for="(item, index) in readyData.questionTypeList"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="问题子类"
          :label-width="'90px'"
          prop="childTagArr"
        >
          <el-select
            v-model="dataForm.childTagArr"
            placeholder="问题子类"
            size="small"
            class="area-form-item"
            :style="{ width: '315px' }"
            multiple
            @change="subChange"
          >
            <el-option
              v-for="(item, index) in subList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item 
          label="说明"
          :label-width="'90px'"
          prop="remark"
        >
          <el-input type="textarea" placeholder="说明" v-model="dataForm.remark" :style="{ width: '315px' }"/>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button type="primary" :loading="loading" @click="dataFormSubmitHandle()">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
export default {
  data() {
    return {
      title: null,
      loading: false,
      ompStatus: null,
      dataForm: {
        cloudId: null,
        action: null,
        contacts: null,
        sn: null,
        index: null,
        nextPerson: null,
        suggest: null,
        remark: null,
        organId: null,
        userId: null,
        tagType: null,
        childTag: null,
        childTagArr: []
      },
      visible: false,
      persons: null,
      readyData: {},
      subList: []
    };
  },
  computed: {
    rules() {
      return {
        action: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        suggest: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        nextPerson: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        contacts: [
          { required: true, message: "必填项不能为空", trigger: "blur" },
        ]
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.dataForm.organId = localStorage.getItem('organId')
      this.dataForm.userId = localStorage.getItem('userId')
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.getPersonList();
        this.getSourceList()
        this.getOmpStatus()
      });
    },
    //获取故障处理人列表
    getPersonList() {
      axios.post("/api/cloudControl/persons", { id: this.dataForm.organId }).then(rs => {
        this.persons = rs;
      });
    },
    getOmpStatus() {
      axios
        .post(`/api/cloudControl/snStatus`, {id: this.dataForm.sn})
        .then((res) => {
          this.ompStatus = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //获取来源列表
    getSourceList() {
      axios
        .post(`/api/customerServiceCenter/ready`, {id: this.dataForm.organId})
        .then((res) => {
          this.readyData = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    changeHandle(value) {
      axios
        .post(`/api/customerServiceCenter/getChild`, { id: value })
        .then((res) => {
          this.subList = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    subChange(value) {
      this.dataForm.childTag = value.join(",");
    },
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true
        axios
          .post(`/api/cloudControl/dealOfflineFault`, this.dataForm)
          .then(res => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false
            }
          })
          .catch((err) => {
            this.loading = false
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>